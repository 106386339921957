@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

.mb-form {
  margin-bottom: 10px;
}

.input-group {
  > .input-group-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    margin-left: -$input-border-width;
  }

  > .input-group-file:focus {
    z-index: 3;
  }

  > .input-group-file:not(:first-child) {
    @include border-left-radius(0);
  }

  &:not(.has-validation) .input-group-file:not(:last-child) {
    @include border-right-radius(0);
  }

  &.has-validation .input-group-file:nth-last-child(n + 3) {
    @include border-right-radius(0);
  }
}

.input-group-file {
  height: $input-height;
  padding: 3px 6px;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius, 0);
}

.preview-pane {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -1.5px;
  border: 3px solid $gray-300;
}

.preview-grid-line {
  margin: -0.5px;
  border: 1px dashed $blue;
}

.preview-grid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
}

.preview-grid-container {
  padding-top: 56.25%;
  position: relative;
}

.dashboard-grid {
  display: grid;
  height: 100vh;
  grid-template:
    "navbar" auto
    "main" 1fr /
    1fr;
}

.dashboard-main {
  grid-area: main;
}

.dashboard-navbar {
  grid-area: navbar;
}

.dashboard-sidebar {
  grid-area: sidebar;
  display: none;
}

.sidebar-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@include media-breakpoint-up(md) {
  .dashboard-grid {
    grid-template:
      "navbar navbar" auto
      "sidebar main" 1fr /
      auto 1fr;
  }

  .dashboard-sidebar {
    display: block;
  }
}
